<template>
  <div>
    <navbar />
    <div class="flix-container-fluid flix-html-html" style="padding: 50px 0; background-color:#717C80; color: #fff;">
      <h1 class="flix-text-center flix-html-h1"><flixIcon :id="'cogwheel'" /> {{ $tc('message.settings', 2) }}</h1>
      <div class="flix-text-center pb_txt">{{ data.data.title }}</div>
    </div>
    <div class="flix-container" v-if="data">
      <div class="flix-form-group">
        <editEvent :data="data" :setReload="function() { setReload() }"/>
      </div>
    </div>
    <div v-if="data" class="flix-container-fluid flix-html-html" style="padding: 20px 0; margin-top: 20px; background-color:#ddd; color: #fff;">
      <div class="flix-container">
        <div class="flix-form-group">
          <bookingUrl />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    navbar () { return import('@/components/frontend/navigation/navigation') },
    bookingUrl () { return import('@/components/privateEvent/bookingUrl') },
    editEvent () { return import('@/components/privateEvent/edit') }
  },
  props: {},
  data () {
    return {
      data: false,
      updateKey: new Date().getTime()
    }
  },
  methods: {
    setReload () {
      this.updateKey = new Date().getTime()
    },
    getData (callback) {
      this.$store.dispatch('getPrivateAssistent', { id: this.$route.params.id, callback: function (data) { callback(data) } })
    }
  },
  mounted () {
    this.getData(function (data) { this.data = data }.bind(this))
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
